@use "variables" as v;
@use "@angular/material" as mat;

$custom-typography: mat.define-typography-config(
  $font-family: v.$font-family,
);

@include mat.checkbox-typography($custom-typography);

@include mat.all-component-typographies($custom-typography);

@font-face {
  font-family: v.$font-family;
  font-display: swap;
  src: url("../assets/fonts/AvenirLTStd-Book.otf");
}

@font-face {
  font-family: v.$font-family;
  font-display: swap;
  font-weight: bold;
  src: url("../assets/fonts/AvenirLTStd-Black.otf");
}

@font-face {
  font-family: v.$font-family;
  font-display: swap;
  font-weight: 300;
  src: url("../assets/fonts/AvenirLTStd-Roman.otf");
}
