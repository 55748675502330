@import "fonts";

/* You can add global styles to this file, and also import other style files */
::ng-deep *,
::ng-deep *::before,
::ng-deep **::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  min-height: 100vh;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  background-color: #f2f7ff;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.material-icons-outlined,
.material-icons.material-icons--outlined {
  font-family: "Material Icons Outlined";
}

// Tooltips

.blue-tooltip .mdc-tooltip__surface {
  background-color: #0b5c8e;
  font-weight: 100;
}

// Buttons

.styled-button {
  background: #0b5c8e;
  border: 1px solid #0b5c8e;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 1rem;
  min-height: 3rem;
  outline: 0;
  padding: 1rem 1.1rem;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.styled-button:hover,
.styled-button:active {
  background-color: initial;
  background-position: 0 0;
  color: #0b5c8e;
}

.styled-button:active {
  opacity: 0.5;
}

.giq-toast-top-right {
  top: 70px;
  right: 12px;
}

.content-section {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: solid 3px white;
  --time: 0.2s;
  --radius: 0.7rem;
  border-radius: var(--radius);
  transition: border var(--time);
  box-shadow: 0px 1px 7px 0px #b3b3b3;
  padding: 0.5rem;
}

.giq-title {
  font-weight: bold;
  display: flex;
  flex: 1;
}

// .main-toast {
//   // margin-top: 3rem;
// }

// .cdk-overlay-pane {
//   overflow-y: auto;
// }

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}
